.croppie-container {
  width: 100%;
  height: 100%;
}

.croppie-container .cr-image {
  z-index: -1;
  transform-origin: 0 0;
  max-width: none;
  max-height: none;
  position: absolute;
  top: 0;
  left: 0;
}

.croppie-container .cr-boundary {
  z-index: 1;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.croppie-container .cr-viewport, .croppie-container .cr-resizer {
  z-index: 0;
  border: 2px solid #fff;
  margin: auto;
  position: absolute;
  inset: 0;
  box-shadow: 0 0 2000px 2000px #00000080;
}

.croppie-container .cr-resizer {
  z-index: 2;
  box-shadow: none;
  pointer-events: none;
}

.croppie-container .cr-resizer-vertical, .croppie-container .cr-resizer-horisontal {
  pointer-events: all;
  position: absolute;
}

.croppie-container .cr-resizer-vertical:after, .croppie-container .cr-resizer-horisontal:after {
  box-sizing: border-box;
  content: "";
  background: #fff;
  border: 1px solid #000;
  width: 10px;
  height: 10px;
  display: block;
  position: absolute;
}

.croppie-container .cr-resizer-vertical {
  cursor: row-resize;
  width: 100%;
  height: 10px;
  bottom: -5px;
}

.croppie-container .cr-resizer-vertical:after {
  margin-left: -5px;
  left: 50%;
}

.croppie-container .cr-resizer-horisontal {
  cursor: col-resize;
  width: 10px;
  height: 100%;
  right: -5px;
}

.croppie-container .cr-resizer-horisontal:after {
  margin-top: -5px;
  top: 50%;
}

.croppie-container .cr-original-image {
  display: none;
}

.croppie-container .cr-vp-circle {
  border-radius: 50%;
}

.croppie-container .cr-overlay {
  z-index: 1;
  cursor: move;
  touch-action: none;
  position: absolute;
}

.croppie-container .cr-slider-wrap {
  text-align: center;
  width: 75%;
  margin: 15px auto;
}

.croppie-result {
  position: relative;
  overflow: hidden;
}

.croppie-result img {
  position: absolute;
}

.croppie-container .cr-image, .croppie-container .cr-overlay, .croppie-container .cr-viewport {
  transform: translateZ(0);
}

.cr-slider {
  -webkit-appearance: none;
  background-color: #0000;
  width: 300px;
  max-width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
}

.cr-slider::-webkit-slider-runnable-track {
  background: #00000080;
  border: 0;
  border-radius: 3px;
  width: 100%;
  height: 3px;
}

.cr-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: #ddd;
  border: none;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  margin-top: -6px;
}

.cr-slider:focus {
  outline: none;
}

.cr-slider::-moz-range-track {
  background: #00000080;
  border: 0;
  border-radius: 3px;
  width: 100%;
  height: 3px;
}

.cr-slider::-moz-range-thumb {
  background: #ddd;
  border: none;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  margin-top: -6px;
}

.cr-slider:-moz-focusring {
  outline-offset: -1px;
  outline: 1px solid #fff;
}

.cr-slider::-ms-track {
  color: #0000;
  background: none;
  border-width: 6px 0;
  border-color: #0000;
  width: 100%;
  height: 5px;
}

.cr-slider::-ms-fill-lower {
  background: #00000080;
  border-radius: 10px;
}

.cr-slider::-ms-fill-upper {
  background: #00000080;
  border-radius: 10px;
}

.cr-slider::-ms-thumb {
  background: #ddd;
  border: none;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  margin-top: 1px;
}

.cr-slider:focus::-ms-fill-lower {
  background: #00000080;
}

.cr-slider:focus::-ms-fill-upper {
  background: #00000080;
}

.cr-rotate-controls {
  z-index: 1;
  position: absolute;
  bottom: 5px;
  left: 5px;
}

.cr-rotate-controls button {
  background: none;
  border: 0;
}

.cr-rotate-controls i:before {
  font-size: 22px;
  font-style: normal;
  font-weight: 900;
  display: inline-block;
}

.cr-rotate-l i:before {
  content: "↺";
}

.cr-rotate-r i:before {
  content: "↻";
}
/*# sourceMappingURL=index.1765aff5.css.map */
